import { gql } from '@apollo/client';
import { CORE_PAGE_INFO_FIELDS } from 'src/components/providers/apollo-provider-provider';

export const CREATE_BROADCAST_MUTATION = gql`
  mutation CreateBroadcast($input: CreateBroadcastInput!) {
    createBroadcast(input: $input) {
      id
      name
      status
      scheduledAt
      audience
    }
  }
`;

export const UPDATE_BROADCAST_MUTATION = gql`
  mutation UpdateBroadcast($input: UpdateBroadcastInput!) {
    updateBroadcast(input: $input) {
      id
      name
      status
      scheduledAt
      audience
    }
  }
`;

export const UPDATE_BROADCAST_STATUS_MUTATION = gql`
  mutation UpdateBroadcastStatus($input: UpdateBroadcastStatusInput!) {
    updateBroadcastStatus(input: $input) {
      id
      name
      status
      scheduledAt
      startedAt
    }
  }
`;

export const GET_BROADCAST_LIST = gql`
  ${CORE_PAGE_INFO_FIELDS}
  query Broadcasts(
    $companyId: Int!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $where: BroadcastListWhereInput
    $orderBy: BroadcastListOrderByInput
  ) {
    broadcasts(
      companyId: $companyId
      after: $after
      before: $before
      first: $first
      last: $last
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      nodes {
        id
        name
        status
        audience
        startedAt
        isArchived
        optOuts
        replies
        sent
        channelsConfiguration {
          ... on BroadcastChannelSmsConfiguration {
            resource {
              phoneNumber {
                national
              }
            }
            content {
              ... on BroadcastChannelSmsContentMessage {
                message
                type
              }
            }
            optOutMessage
          }
        }
      }
    }
  }
`;

export const GET_BROADCAST_MODAL_DETAILS = gql`
  query Broadcast($id: String!) {
    broadcast(id: $id) {
      id
      name
      status
      startedAt
      isArchived
      audience
      optOuts
      replies
      sent
      channelsConfiguration {
        ... on BroadcastChannelSmsConfiguration {
          resource {
            id
            phoneNumber {
              national
            }
          }
          content {
            ... on BroadcastChannelSmsContentMessage {
              message
              type
            }
          }
          optOutMessage
        }
      }
    }
  }
`;

export const GET_BROADCAST_EDIT_DETAILS = gql`
  query Broadcast($id: String!) {
    broadcast(id: $id) {
      id
      name
      status
      scheduledAt
      audience
      criteria
      channelsConfiguration {
        ... on BroadcastChannelSmsConfiguration {
          resource {
            id
            phoneNumber {
              national
            }
          }
          content {
            ... on BroadcastChannelSmsContentMessage {
              message
              type
            }
          }
          optOutMessage
        }
      }
      deliveryTime {
        ... on BroadcastDeliveryTimeCustom {
          daysOfWeek {
            dayOfWeek
            timeWindow {
              startTime
              endTime
            }
          }
        }
        ... on BroadcastDeliveryTimePreset {
          presetType
          timeWindow {
            startTime
            endTime
          }
        }
      }
    }
  }
`;
