import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { match, P } from 'ts-pattern';
import { ColorPaletteItem } from '@lgg/isomorphic';
import {
  ContactInteractionUnion,
  Contact,
  ContactTimelineUser,
  PhoneNumber,
  ContactInteractionParticipatingEntityEdge,
  ContactTimelineUnion,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { matchContactInteractionExhaustive } from '@lgg/isomorphic/utils/match-contact-interaction';
import { getAppointmentStatusTransKey } from 'src/components/domain/appointments/helpers/get-appointment-status-trans-key';
import { InProgressCallIndicator } from 'src/components/domain/contact-interaction/in-progress-call-indicator';
import { getTaskStatusTransKey } from 'src/components/domain/tasks/helpers/get-task-status-trans-key';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useCurrentUserId } from 'src/hooks/use-current-user';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useUrls } from 'src/hooks/use-urls';

export const useContactTimelineDetails = (
  contactTimeline: ContactTimelineUnion,
  contact: Contact,
) => {
  const { t } = useTranslation(['common', 'contactTimeline', 'tasks', 'appointments']);
  const userId = useCurrentUserId();
  const { formatDueDate, formatDate } = useFormatDate();
  const {
    getContactModalUrl,
    getTaskModalUrl,
    getScheduleModalUrl,
    getLegacyContactEditNoteUrl,
  } = useUrls();

  const iconProps = useMemo(() => {
    let iconConfig: { type: string; color: ColorPaletteItem } | null = null;

    switch (contactTimeline.__typename) {
      case 'ContactTimelineContactCreated':
      case 'ContactTimelineContactStatusUpdated':
      case 'ContactTimelineContactUpdated':
        iconConfig = { type: 'contact', color: 'complementaryDarkColor1' };
        break;
      case 'ContactTimelineTaskCreated':
      case 'ContactTimelineTaskStatusUpdated':
      case 'ContactTimelineTaskDeleted':
        iconConfig = { type: 'task18', color: 'secondaryMintDark' };
        break;
      case 'ContactTimelineScheduleCreated':
      case 'ContactTimelineScheduleStatusUpdated':
      case 'ContactTimelineScheduleDeleted':
        iconConfig = { type: 'schedule18', color: 'secondaryPeriwinkleDark' };
        break;
      case 'ContactTimelineNoteCreated':
      case 'ContactTimelineNoteDeleted':
        iconConfig = { type: 'note18', color: 'secondaryFlowerDark' };
        break;
      case 'ContactTimelineContactInteraction':
        if (!contactTimeline.contactInteraction) {
          iconConfig = { type: 'interaction', color: 'secondaryTopazDark' };
          break;
        }

        switch (contactTimeline.contactInteraction.__typename) {
          case 'ContactInteractionInPerson':
            iconConfig = { type: 'inPersonLog', color: 'secondaryTopazDark' };
            break;
          case 'ContactInteractionUnknown':
            iconConfig = { type: 'interaction', color: 'secondaryTopazDark' };
            break;
          case 'ContactInteractionSms':
            iconConfig = { type: 'sms', color: 'secondaryTopazDark' };
            break;
          case 'ContactInteractionFacebookMessenger':
            iconConfig = { type: 'facebookMessenger', color: 'secondaryTopazDark' };
            break;
          case 'ContactInteractionWhatsapp':
            iconConfig = { type: 'whatsapp', color: 'secondaryTopazDark' };
            break;
          case 'ContactInteractionInstagram':
            iconConfig = { type: 'instagramAlt', color: 'secondaryTopazDark' };
            break;
          case 'ContactInteractionEmail':
            iconConfig = { type: 'email', color: 'secondaryTopazDark' };
            break;
          case 'ContactInteractionPhoneCall':
            iconConfig = { type: 'phone', color: 'secondaryTopazDark' };
            break;
          case 'ContactInteractionFacebookAd':
            iconConfig = { type: 'iconFacebookForm', color: 'secondaryTopazDark' };
            break;
        }
        break;
    }

    if (iconConfig) {
      return iconConfig;
    }

    return null;
  }, [contactTimeline]);

  const text = useMemo(() => {
    const resolveOriginatorLabel = (
      originator:
        | ContactTimelineUser
        | ContactInteractionParticipatingEntityEdge
        | null
        | undefined
        | PhoneNumber,
    ) => {
      const fallbackLabel = 'TBD';

      if (!originator) {
        return fallbackLabel;
      }

      try {
        return match(originator)
          .with(
            P.union({ __typename: 'ContactInteractionParticipatingEntityEdge' }),
            (entity) => {
              if (entity.type === 'AGENT' && entity.id === userId.toString()) {
                return t('common:you');
              }

              return entity.label;
            },
          )
          .with({ __typename: 'PhoneNumber' }, (phone) => phone.national)
          .with({ __typename: 'ContactTimelineUser' }, (user) => {
            if (user.id === userId) {
              return t('common:you');
            }

            return user.fullName;
          })
          .exhaustive();
      } catch (_error) {
        return fallbackLabel;
      }
    };

    const inProgressIndicator = <InProgressCallIndicator />;

    const resolveContactInteractionComponents = (
      contactInteraction: ContactInteractionUnion,
    ) => {
      const contactInteractionUrl = getContactModalUrl(contact.id, {
        contactInteractionId: contactInteraction.id,
        conversationId: contact.lastConversation.id,
      });

      return {
        strong: <span />,
        modalLink: (
          <Link
            className="link"
            replace={true}
            to={contactInteractionUrl}
            data-lgg-id={`contact-interaction-link-id-${contactInteraction.id}`}
          />
        ),
      };
    };

    return match(contactTimeline)
      .with({ __typename: 'ContactTimelineContactCreated' }, () => {
        return (
          <Trans i18nKey="contactTimeline:logs.contact.created" components={[<span />]} />
        );
      })
      .with({ __typename: 'ContactTimelineContactStatusUpdated' }, (contactTimeline) => {
        return (
          <Trans
            i18nKey="contactTimeline:logs.contact.statusUpdated"
            values={{
              originator: resolveOriginatorLabel(contactTimeline.originator),
              oldStatus: contactTimeline.oldStatus.name,
              newStatus: contactTimeline.newStatus.name,
            }}
            components={[<span />]}
          />
        );
      })
      .with({ __typename: 'ContactTimelineContactUpdated' }, (contactTimeline) => {
        return (
          <Trans
            i18nKey="contactTimeline:logs.contact.updated"
            values={{
              originator: resolveOriginatorLabel(contactTimeline.originator),
            }}
            components={[<span />]}
          />
        );
      })
      .with({ __typename: 'ContactTimelineTaskCreated' }, (contactTimeline) => {
        return (
          <Trans
            i18nKey="contactTimeline:logs.task.created"
            values={{
              originator: resolveOriginatorLabel(contactTimeline.originator),
              title: contactTimeline.task.title,
            }}
            components={{
              strong: <span />,
              modalLink: <Link to={getTaskModalUrl(contactTimeline.task.id)} />,
            }}
          />
        );
      })
      .with({ __typename: 'ContactTimelineTaskStatusUpdated' }, (contactTimeline) => {
        const oldStatus = t(getTaskStatusTransKey(contactTimeline.oldTaskStatus));
        const newStatus = t(getTaskStatusTransKey(contactTimeline.newTaskStatus));

        return (
          <Trans
            i18nKey="contactTimeline:logs.task.statusUpdated"
            values={{
              originator: resolveOriginatorLabel(contactTimeline.originator),
              title: contactTimeline.task.title,
              oldStatus,
              newStatus,
            }}
            components={{
              strong: <span />,
              modalLink: <Link to={getTaskModalUrl(contactTimeline.task.id)} />,
            }}
          />
        );
      })
      .with({ __typename: 'ContactTimelineTaskDeleted' }, (contactTimeline) => {
        return (
          <Trans
            i18nKey="contactTimeline:logs.task.deleted"
            values={{
              originator: resolveOriginatorLabel(contactTimeline.originator),
              title: contactTimeline.taskTitle,
            }}
            components={{
              strong: <span />,
            }}
          />
        );
      })
      .with({ __typename: 'ContactTimelineScheduleCreated' }, (contactTimeline) => {
        return (
          <Trans
            i18nKey="contactTimeline:logs.appointment.created"
            values={{
              originator: resolveOriginatorLabel(contactTimeline.originator),
              title: contactTimeline.schedule.title,
            }}
            components={[
              <span />,
              <Link to={getScheduleModalUrl(contactTimeline.schedule.id, contact.id)} />,
            ]}
          />
        );
      })
      .with(
        { __typename: 'ContactTimelineScheduleStatusUpdated' },

        (contactTimeline) => {
          const oldStatus = t(
            getAppointmentStatusTransKey(contactTimeline.oldScheduleStatus),
          );
          const newStatus = t(
            getAppointmentStatusTransKey(contactTimeline.newScheduleStatus),
          );

          return (
            <Trans
              i18nKey="contactTimeline:logs.appointment.statusUpdated"
              values={{
                originator: resolveOriginatorLabel(contactTimeline.originator),
                title: contactTimeline.schedule.title,
                oldStatus,
                newStatus,
              }}
              components={{
                strong: <span />,
                modalLink: (
                  <Link
                    to={getScheduleModalUrl(contactTimeline.schedule.id, contact.id)}
                  />
                ),
              }}
            />
          );
        },
      )
      .with({ __typename: 'ContactTimelineScheduleDeleted' }, (contactTimeline) => {
        return (
          <Trans
            i18nKey="contactTimeline:logs.appointment.deleted"
            values={{
              originator: resolveOriginatorLabel(contactTimeline.originator),
              title: contactTimeline.appointmentTitle,
            }}
            components={{
              strong: <span />,
            }}
          />
        );
      })
      .with({ __typename: 'ContactTimelineNoteCreated' }, (contactTimeline) => {
        return (
          <Trans
            i18nKey="contactTimeline:logs.note.created"
            values={{
              originator: resolveOriginatorLabel(contactTimeline.originator),
            }}
            components={{
              strong: <span />,
              noteLink: (
                <span
                  className="link"
                  onClick={() => {
                    openRemoteModalWrapper(
                      getLegacyContactEditNoteUrl(contact.id, contactTimeline.note.id),
                    );
                  }}
                />
              ),
            }}
          />
        );
      })
      .with({ __typename: 'ContactTimelineNoteDeleted' }, (contactTimeline) => {
        return (
          <Trans
            i18nKey="contactTimeline:logs.note.deleted"
            values={{
              originator: resolveOriginatorLabel(contactTimeline.originator),
            }}
            components={{ strong: <span /> }}
          />
        );
      })
      .with({ __typename: 'ContactTimelineContactInteraction' }, (contactTimeline) => {
        if (!contactTimeline.contactInteraction) {
          return (
            <Trans
              i18nKey="contactTimeline:logs.contactInteraction.deletedInteraction"
              values={{
                originator: resolveOriginatorLabel(contactTimeline.originator),
              }}
              components={{ strong: <span /> }}
            />
          );
        }

        const components = resolveContactInteractionComponents(
          contactTimeline.contactInteraction,
        );

        const getInboundAnsweredPhoneByTitle = () => {
          return (
            <Trans
              i18nKey="contactTimeline:logs.contactInteraction.call.inboundAnsweredWithoutParticipatingEntity"
              components={components}
            />
          );
        };

        return matchContactInteractionExhaustive(contactTimeline.contactInteraction, {
          unknown: () => (
            <Trans i18nKey="contactTimeline:logs.contactInteraction.unknown" />
          ),
          facebookAd: () => (
            <Trans
              i18nKey="contactTimeline:logs.contactInteraction.facebookAd"
              components={components}
            />
          ),
          phone: {
            defaultInbound: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.defaultInbound"
                components={components}
              />
            ),
            inboundInProgress: () => (
              <>
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.defaultInbound"
                  components={components}
                />
                {inProgressIndicator}
              </>
            ),
            defaultOutbound: ({ participatingEntity }) => {
              return (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.defaultOutbound"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
              );
            },
            outboundInProgress: ({ participatingEntity }) => (
              <>
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.defaultOutbound"
                  values={{
                    originator: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
                {inProgressIndicator}
              </>
            ),
            inboundManualRegistrationWithParticipatingEntity: ({
              participatingEntity,
            }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundManualRegistrationWithParticipatingEntity"
                values={{
                  participatingEntityLabel: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            inboundManualRegistrationWithoutParticipatingEntity: () => {
              return (
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundManualRegistrationWithoutParticipatingEntity"
                  components={components}
                />
              );
            },
            inboundAnsweredWithParticipatingEntity: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundAnsweredWithParticipatingEntity"
                values={{
                  participatingEntityLabel: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            inboundAnsweredWithParticipatingEntityInProgress: ({
              participatingEntity,
            }) => (
              <>
                <Trans
                  i18nKey="contactTimeline:logs.contactInteraction.call.inboundAnsweredWithParticipatingEntity"
                  values={{
                    participatingEntityLabel: resolveOriginatorLabel(participatingEntity),
                  }}
                  components={components}
                />
                {inProgressIndicator}
              </>
            ),
            inboundAnsweredWithoutParticipatingEntity: () =>
              getInboundAnsweredPhoneByTitle(),
            inboundAnsweredWithoutParticipatingEntityInProgress: () => (
              <>
                {getInboundAnsweredPhoneByTitle()}
                {inProgressIndicator}
              </>
            ),
            inboundUnansweredWithDialStepMenu: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundUnansweredWithDialStepMenu"
                components={components}
              />
            ),
            inboundUnansweredWithDialStepMessage: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundUnansweredWithDialStepMessage"
                components={components}
              />
            ),
            inboundUnansweredWithDialStepDial: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundUnansweredWithDialStepDial"
                components={components}
              />
            ),
            inboundUnansweredWithDialStepVoicemailWithRecording: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundUnansweredWithDialStepVoicemailWithRecording"
                components={components}
              />
            ),
            inboundUnansweredWithDialStepVoicemailWithoutRecording: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundUnansweredWithDialStepVoicemailWithoutRecording"
                components={components}
              />
            ),
            inboundUnansweredWithoutDialStep: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundUnansweredWithoutDialStep"
                components={components}
              />
            ),
            inboundOtherStatusDialStepMenu: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundOtherStatusDialStepMenu"
                components={components}
              />
            ),
            inboundOtherStatusDialStepMessage: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundOtherStatusDialStepMessage"
                components={components}
              />
            ),
            inboundOtherStatusDialStepDial: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundOtherStatusDialStepDial"
                components={components}
              />
            ),
            inboundOtherStatusDialStepVoicemailWithRecording: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundOtherStatusDialStepVoicemailWithRecording"
                components={components}
              />
            ),
            inboundOtherStatusDialStepVoicemailWithoutRecording: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundOtherStatusDialStepVoicemailWithoutRecording"
                components={components}
              />
            ),
            inboundOtherStatusNoStep: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.inboundOtherStatusNoStep"
                components={components}
              />
            ),
            outboundManualRegistration: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.outboundManualRegistration"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            outboundAnswered: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.outboundAnswered"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            outboundWithCancelReason: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.outboundWithCancelReason"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            outboundUnansweredWithBusyReason: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.outboundUnansweredWithBusyReason"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            outboundUnansweredWithFailedReason: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.outboundUnansweredWithFailedReason"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            outboundUnansweredWithOtherReason: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.outboundUnansweredWithOtherReason"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            outboundOtherStatus: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.call.outboundOtherStatus"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
          },
          inPerson: {
            withoutParticipatingEntity: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.inPerson.withoutParticipatingEntity"
                components={components}
              />
            ),
            withParticipatingEntity: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.inPerson.withParticipatingEntity"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
          },
          sms: {
            outbound: ({ participatingEntity }) => {
              const originatorLabel = resolveOriginatorLabel(participatingEntity);

              const translationKey = match(participatingEntity)
                .with(
                  { type: 'CALLFLOW' },
                  () =>
                    'contactTimeline:logs.contactInteraction.sms.outbound.callflow' as const,
                )
                .with(
                  { type: 'BROADCAST' },
                  () =>
                    'contactTimeline:logs.contactInteraction.sms.outbound.broadcast' as const,
                )
                .otherwise(
                  () =>
                    'contactTimeline:logs.contactInteraction.sms.outbound.default' as const,
                );

              return (
                <Trans
                  i18nKey={translationKey}
                  values={{
                    originator: originatorLabel,
                  }}
                  components={components}
                />
              );
            },
            inboundWithParticipatingEntity: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.sms.inboundWithParticipatingEntity"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            inboundWithoutParticipatingEntity: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.sms.inboundWithoutParticipatingEntity"
                components={components}
              />
            ),
          },
          whatsapp: {
            outbound: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.whatsapp.outbound"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            inboundWithParticipatingEntity: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.whatsapp.inboundWithParticipatingEntity"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            inboundWithoutParticipatingEntity: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.whatsapp.inboundWithoutParticipatingEntity"
                components={components}
              />
            ),
          },
          email: {
            outbound: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.email.outbound"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            inboundWithParticipatingEntity: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.email.inboundWithParticipatingEntity"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            inboundWithoutParticipatingEntity: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.email.inboundWithoutParticipatingEntity"
                components={components}
              />
            ),
          },
          facebookMessenger: {
            outbound: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.facebookMessenger.outbound"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            inboundWithParticipatingEntity: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.facebookMessenger.inboundWithParticipatingEntity"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            inboundWithoutParticipatingEntity: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.facebookMessenger.inboundWithoutParticipatingEntity"
                components={components}
              />
            ),
          },
          instagram: {
            outbound: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.instagram.outbound"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            inboundWithParticipatingEntity: ({ participatingEntity }) => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.instagram.inboundWithParticipatingEntity"
                values={{
                  originator: resolveOriginatorLabel(participatingEntity),
                }}
                components={components}
              />
            ),
            inboundWithoutParticipatingEntity: () => (
              <Trans
                i18nKey="contactTimeline:logs.contactInteraction.instagram.inboundWithoutParticipatingEntity"
                components={components}
              />
            ),
          },
        });
      })
      .otherwise(() => {
        // no need to handle other events
      });
  }, [
    contactTimeline,
    userId,
    t,
    getTaskModalUrl,
    getScheduleModalUrl,
    contact.id,
    contact.lastConversation.id,
    getLegacyContactEditNoteUrl,
    getContactModalUrl,
  ]);

  const formattedDate = useMemo(
    () => formatDate(contactTimeline.occurredAt, 'H:MM A'),
    [formatDate, contactTimeline.occurredAt],
  );

  const formattedDueDate = useMemo(
    () => formatDueDate(contactTimeline.occurredAt),
    [formatDueDate, contactTimeline.occurredAt],
  );

  return { iconProps, text, formattedDate, formattedDueDate };
};
